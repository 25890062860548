import { DateTime } from "luxon";
import { Activity } from "@/interfaces/activity";
import { Location } from "@/interfaces/location";
import { Section } from "@/interfaces/section";

const demoAsset = {
  id: 1,
  name: "Demo Asset",
  description: "Asset used for demonstration purposes",
};

const defaultSection: Section = {
  id: 1,
  name: "Default",
  description: "No description",
  start: 2.7,
  end: 3824.1,
  asset_id: demoAsset.id,
};

const demoDevice = {
  id: 1,
  serial_number: "virtual-treble",
  ip_address: null,
  display_name: "Demo Treble",
  dx: 4.9,
};

const smallLeakLocations: Location[] = [
  {
    id: 154,
    fibre_index: 195,
    lat: -8.98810768144291,
    lon: 116.810448577022,
    device_id: demoDevice.id,
    distance_m: 412.1,
    address: "442.1m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
  {
    id: 155,
    fibre_index: 196,
    lat: -8.98814781954192,
    lon: 116.810433102542,
    device_id: demoDevice.id,
    distance_m: 414.8,
    address: "444.8m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
  {
    id: 156,
    fibre_index: 197,
    lat: -8.98818795764093,
    lon: 116.810417628062,
    device_id: demoDevice.id,
    distance_m: 417.5,
    address: "447.5m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
  {
    id: 157,
    fibre_index: 198,
    lat: -8.98822809573995,
    lon: 116.810402153583,
    device_id: demoDevice.id,
    distance_m: 420.1,
    address: "450.1m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
  {
    id: 158,
    fibre_index: 199,
    lat: -8.98826823383896,
    lon: 116.810386679103,
    device_id: demoDevice.id,
    distance_m: 422.8,
    address: "452.8m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
  {
    id: 159,
    fibre_index: 200,
    lat: -8.98830837193797,
    lon: 116.810371204623,
    device_id: demoDevice.id,
    distance_m: 425.5,
    address: "455.5m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
];

const smallLeakLabel = {
  id: "b9827315-3b88-4926-8df4-2d9c43c60057",
  label: "Suspected Small Leak",
  value: 200,
  model_id: 1,
  color: "#ff0027",
};

const smallLeakActivity = {
  id: 1,
  name: "Small Leak",
  start_time: DateTime.now().toISO(),
  end_time: DateTime.now().toISO(),
  status: "Open",
  cause: "Pipe failure",
  updated_at: "2023-04-01T12:00:00Z",
  last_infrastructure_state: DateTime.now().toISO(),
  latest_location_included_at: "2023-04-01T09:00:00Z",
  locations: smallLeakLocations,
  initial_classification: smallLeakLabel,
  final_classification: smallLeakLabel,
  classification: smallLeakLabel,
  acknowledged_at: null,
  acknowledged_by: null,
  nearest_epicenter_location:
    smallLeakLocations[Math.floor((smallLeakLocations.length - 1) / 2)],
};

const largeLeakLocations = [
  {
    id: 799,
    fibre_index: 840,
    lat: -9.00906870610584,
    lon: 116.802319296567,
    device_id: demoDevice.id,
    distance_m: 2138.2,
    address: "2168.2m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
  {
    id: 800,
    fibre_index: 841,
    lat: -9.00910740901509,
    lon: 116.802335746894,
    device_id: demoDevice.id,
    distance_m: 2140.8,
    address: "2170.8m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
  {
    id: 801,
    fibre_index: 842,
    lat: -9.00914611192434,
    lon: 116.802352197221,
    device_id: demoDevice.id,
    distance_m: 2143.5,
    address: "2173.5m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
];

const largeLeakLabel = {
  id: "b9827315-3b88-4926-8df4-2d9c43c60062",
  label: "Suspected Large Leak",
  value: 300,
  model_id: 1,
  color: "#c90d00",
};

const largeLeakActivity = {
  id: 2,
  name: "Large Leak",
  start_time: DateTime.now().toISO(),
  end_time: DateTime.now().toISO(),
  status: "Open",
  cause: "Pipe failure",
  updated_at: "2023-04-01T12:00:00Z",
  last_infrastructure_state: DateTime.now().toISO(),
  latest_location_included_at: "2023-04-01T09:00:00Z",
  locations: largeLeakLocations,
  initial_classification: largeLeakLabel,
  final_classification: largeLeakLabel,
  classification: largeLeakLabel,
  acknowledged_at: null,
  acknowledged_by: null,
  nearest_epicenter_location:
    largeLeakLocations[Math.floor((largeLeakLocations.length - 1) / 2)],
};

const mechanicalDiggingLabel = {
  label: "Mechanical Digging",
  value: 170,
  model_id: 1,
  color: "#c5b0d5",
  creates_activities: null,
  active: true,
  id: "3df25aae-80e6-4258-a3a3-e90078dcd3db",
};

const mechanicalDiggingLocations: Location[] = [
  {
    id: 1205,
    fibre_index: 1246,
    lat: -9.02116958769497,
    lon: 116.806273195838,
    device_id: demoDevice.id,
    distance_m: 3224.6,
    address: "3254.6m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
  {
    id: 1204,
    fibre_index: 1245,
    lat: -9.02112150164245,
    lon: 116.806283606737,
    device_id: demoDevice.id,
    distance_m: 3222,
    address: "3252.0m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
  {
    id: 1203,
    fibre_index: 1244,
    lat: -9.02107341558994,
    lon: 116.806294017637,
    device_id: demoDevice.id,
    distance_m: 3219.3,
    address: "3249.3m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
  {
    id: 1202,
    fibre_index: 1243,
    lat: -9.02102532953742,
    lon: 116.806304428536,
    device_id: demoDevice.id,
    distance_m: 3216.6,
    address: "3246.6m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
  {
    id: 1201,
    fibre_index: 1242,
    lat: -9.02097724348491,
    lon: 116.806314839435,
    device_id: demoDevice.id,
    distance_m: 3213.9,
    address: "3243.9m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
  {
    id: 1200,
    fibre_index: 1241,
    lat: -9.02092915743239,
    lon: 116.806325250335,
    device_id: demoDevice.id,
    distance_m: 3211.3,
    address: "3241.3m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
  {
    id: 1199,
    fibre_index: 1240,
    lat: -9.02088107137987,
    lon: 116.806335661234,
    device_id: demoDevice.id,
    distance_m: 3208.6,
    address: "3238.6m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
  {
    id: 1198,
    fibre_index: 1239,
    lat: -9.02083298532736,
    lon: 116.806346072133,
    device_id: demoDevice.id,
    distance_m: 3205.9,
    address: "3235.9m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
  {
    id: 1197,
    fibre_index: 1238,
    lat: -9.02078489927484,
    lon: 116.806356483033,
    device_id: demoDevice.id,
    distance_m: 3203.2,
    address: "3233.2m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
  {
    id: 1196,
    fibre_index: 1237,
    lat: -9.02073681322232,
    lon: 116.806366893932,
    device_id: demoDevice.id,
    distance_m: 3200.6,
    address: "3230.6m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
  {
    id: 1195,
    fibre_index: 1236,
    lat: -9.02068872716981,
    lon: 116.806377304831,
    device_id: demoDevice.id,
    distance_m: 3197.9,
    address: "3227.9m",
    section: defaultSection,
    section_id: defaultSection.id,
    device: demoDevice,
  },
];

const mechanicalDiggingActivity = {
  id: 3,
  name: "Mechanical Digging Activity",
  start_time: DateTime.now().toISO(),
  end_time: DateTime.now().toISO(),
  status: "Open",
  cause: "Excavation activity",
  updated_at: "2023-04-01T12:00:00Z",
  last_infrastructure_state: DateTime.now().toISO(),
  latest_location_included_at: "2023-04-01T09:00:00Z",
  locations: mechanicalDiggingLocations,
  initial_classification: mechanicalDiggingLabel,
  final_classification: mechanicalDiggingLabel,
  classification: mechanicalDiggingLabel,
  acknowledged_at: null,
  acknowledged_by: null,
  nearest_epicenter_location:
    mechanicalDiggingLocations[
      Math.floor((mechanicalDiggingLocations.length - 1) / 2)
    ],
};

export const demonstrationActivities: Activity[] = [
  smallLeakActivity,
  largeLeakActivity,
  mechanicalDiggingActivity,
];
