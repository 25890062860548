import { eventMonitor } from "@/api/eventMonitor";
import {
  ActivitiesPaginated,
  Activity,
  ActivityUpdate,
  Alarm,
} from "@/interfaces/activity";
import { dispatchCheckApiError } from "@/store/main/actions";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "@/store/state";
import { ActionContext } from "vuex";
import { demonstrationActivities } from "@/store/activities/demonstration";
import { commitAddNotification } from "@/store/main/mutations";
import { getIcon, getIconSVG } from "@/utils";
import { DateTime } from "luxon";

export interface ActivitiesState {
  activities: Activity[];
  filteredActivities: ActivitiesPaginated;
  selectedActivity: Activity | null;
}

const defaultState: ActivitiesState = {
  activities: [],
  filteredActivities: { total: 0, items: [] },
  selectedActivity: null,
};

// Getters
const getters = {
  openActivities: (state: ActivitiesState): Activity[] => {
    return state.activities.filter((activity: Activity) => {
      return activity.status.toLowerCase() === "open";
    });
  },

  alarms: (state: ActivitiesState): Alarm[] => {
    return getters.openActivities(state).map((activity: Activity) => {
      return {
        activity_id: activity.id,
        icon_url: getIconSVG(activity.classification),
        lat: activity.nearest_epicenter_location.lat,
        lon: activity.nearest_epicenter_location.lon,
        name: activity.name,
        address: activity.nearest_epicenter_location.address,
        classification: activity.initial_classification.label,
        start_time: DateTime.fromISO(activity.start_time).toLocaleString(),
        color: activity.initial_classification.color,
        asset_id: activity.nearest_epicenter_location.section.asset_id,
        icon: getIcon(activity.initial_classification),
      };
    });
  },

  totalFilteredActivities: (state: ActivitiesState): number => {
    return state.filteredActivities.total;
  },

  filteredActivities: (state: ActivitiesState): Activity[] => {
    return state.filteredActivities.items.sort((a, b) => {
      return a.start_time > b.start_time ? 1 : -1;
    });
  },

  selectedActivity: (state: ActivitiesState): Activity | null => {
    return state.selectedActivity;
  },
};

const { read } = getStoreAccessors<ActivitiesState, State>("");

export const readOpenActivities = read(getters.openActivities);
export const readAlarms = read(getters.alarms);
export const readTotalFilteredActivities = read(
  getters.totalFilteredActivities
);
export const readFilteredActivities = read(getters.filteredActivities);
export const readSelectedActivity = read(getters.selectedActivity);

// Mutations

const mutations = {
  setActivities(state: ActivitiesState, activities: Activity[]): void {
    state.activities = activities;
  },
  setActivity(state: ActivitiesState, activity: Activity): void {
    const activities = state.activities.filter(
      (a: Activity) => a.id !== activity.id
    );
    activities.push(activity);
    state.activities = activities;
  },
  setFilteredActivities(
    state: ActivitiesState,
    activities: ActivitiesPaginated
  ): void {
    state.filteredActivities = activities;
  },
  setSelectedActivity(state: ActivitiesState, activity: Activity): void {
    state.selectedActivity = activity;
  },
};

const { commit } = getStoreAccessors<ActivitiesState, State>("");

export const commitSetActivities = commit(mutations.setActivities);
export const commitSetActivity = commit(mutations.setActivity);
export const commitSetFilteredActivities = commit(
  mutations.setFilteredActivities
);
export const commitSetSelectedActivity = commit(mutations.setSelectedActivity);

// Actions

type MainContext = ActionContext<ActivitiesState, State>;

const actions = {
  async actionGetOpenActivities(context: MainContext): Promise<void> {
    try {
      if (
        context.rootState.siteConfig.demoMode &&
        context.rootState.main.userProfile?.can_acknowledge_activities
      ) {
        commitAddNotification(context, {
          content: "Demo mode is enabled, using demonstration activities.",
          color: "info",
        });
        commitSetActivities(context, demonstrationActivities);
      } else {
        const response = await eventMonitor.getOpenActivities(
          context.rootState.main.token
        );
        commitSetActivities(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionUpdateActivity(
    context: MainContext,
    payload: { id: number; activity: ActivityUpdate }
  ): Promise<void> {
    try {
      if (context.rootState.siteConfig.demoMode) {
        // Update the activity, then commit the update
        const toUpdate = demonstrationActivities.find(
          (activity) => activity.id === payload.id
        );
        const updated = { ...toUpdate, ...payload.activity };
        commitSetActivity(context, updated);
      } else {
        const response = await eventMonitor.updateActivity(
          context.rootState.main.token,
          payload.id,
          payload.activity
        );
        commitSetActivity(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionAcknowledgeAllOpenActivities(
    context: MainContext
  ): Promise<void> {
    try {
      if (context.rootState.siteConfig.demoMode) {
        // Acknowledge all open activities
        const acknowledgedActivities = demonstrationActivities.map(
          (activity) => {
            return { ...activity, status: "acknowledged" };
          }
        );
        commitSetActivities(context, acknowledgedActivities);
      } else {
        await eventMonitor.acknowledgeAllOpenActivities(
          context.rootState.main.token,
          {
            acknowledged_by: context.rootState.main.userProfile.email,
          }
        );
        commitSetActivities(context, []);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionGetFilteredActivities(
    context: MainContext,
    payload: {
      skip?: number;
      limit?: number;
      sort_by?: string;
      sort_desc?: boolean;
      device_ids?: number[];
      asset_ids?: number[];
      statuses?: string[];
      label_ids?: string[];
      start_time?: string;
      end_time?: string;
    }
  ): Promise<void> {
    if (context.rootState.siteConfig.demoMode) {
      let activities = demonstrationActivities;

      if (payload.device_ids.length) {
        activities = activities.filter((activity: Activity) =>
          payload.device_ids.includes(activity.locations[0].device_id)
        );
      }
      if (payload.asset_ids.length) {
        activities = activities.filter((activity: Activity) =>
          payload.asset_ids.includes(
            activity.nearest_epicenter_location.section.asset_id
          )
        );
      }
      if (payload.statuses.length) {
        activities = activities.filter((activity: Activity) =>
          payload.statuses.includes(activity.status)
        );
      }
      if (payload.label_ids.length) {
        activities = activities.filter((activity: Activity) =>
          payload.label_ids.includes(
            activity.initial_classification.id.toString()
          )
        );
      }
      if (payload.start_time && payload.end_time) {
        activities = activities.filter((activity: Activity) => {
          return (
            DateTime.fromISO(payload.start_time) <=
              DateTime.fromISO(activity.end_time) &&
            DateTime.fromISO(activity.start_time) <=
              DateTime.fromISO(payload.end_time)
          );
        });
      }

      activities = activities.sort((a, b) => {
        return a.start_time > b.start_time ? 1 : -1;
      });

      commitSetFilteredActivities(context, {
        items: activities,
        total: demonstrationActivities.length,
      });
    } else {
      console.log("Getting activities via API");
      const response = await eventMonitor.getActivities(
        context.rootState.main.token,
        payload
      );
      commitSetFilteredActivities(context, response.data);
    }
  },

  async actionGetSelectedActivity(
    context: MainContext,
    id: number
  ): Promise<void> {
    try {
      if (context.rootState.siteConfig.demoMode) {
        const activity = demonstrationActivities.find(
          (activity) => activity.id === id
        );
        commitSetSelectedActivity(context, activity);
      } else {
        const response = await eventMonitor.getActivity(
          context.rootState.main.token,
          id
        );
        commitSetSelectedActivity(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<ActivitiesState, State>("");

export const dispatchActionGetOpenActivities = dispatch(
  actions.actionGetOpenActivities
);
export const dispatchActionUpdateActivity = dispatch(
  actions.actionUpdateActivity
);
export const dispatchActionAcknowledgeAllOpenActivities = dispatch(
  actions.actionAcknowledgeAllOpenActivities
);
export const dispatchActionGetFilteredActivities = dispatch(
  actions.actionGetFilteredActivities
);
export const dispatchActionGetSelectedActivity = dispatch(
  actions.actionGetSelectedActivity
);

// Module

export const activitiesModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
